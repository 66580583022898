<template>
  <div class="user-home">
    <div class="company-info">
      <div class="info">
        <div class="welcome">您好！{{userInfo.name}}</div>
        <div class="company-name">{{userInfo.company_name}}</div>
        <div class="mobile">绑定手机：{{userInfo.mobile}}</div>
      </div>
      <div @click="isShowCompanyAuth = true" v-if="userInfo.company_auth.auth_status === null" class="btn">升级认证企业用户</div>
      <div v-else-if="userInfo.company_auth.auth_status === 0" class="btn" style="cursor: not-allowed;">认证审核中</div>
      <div @click="isShowCompanyAuth = true" v-else-if="userInfo.company_auth.auth_status === 1" class="btn">已拒审，重新认证</div>
    </div>
    <CompanyAuth @hideCompanyAuth="hideCompanyAuth" v-if="isShowCompanyAuth" />
  </div>
</template>
<script>
import CompanyAuth from '@/components/user/company-auth.vue'
import { mapState } from 'vuex'
export default {
  components: {
    CompanyAuth
  },
  data () {
    return {
      isShowCompanyAuth: false
    }
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  methods: {
    hideCompanyAuth () {
      this.isShowCompanyAuth = false
    }
  }
}
</script>
<style lang="scss" scoped>
.user-home {
  .company-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 20px;
    .btn {
      border-radius: 3px;
      background-color: #3385FF;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 38px;
      color: #ffffff;
      font-size: 13px;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
    .info {
      line-height: 1.2;
      .welcome {
        font-size: 18px;
      }
      .company-name {
        font-size: 14px;
        margin: 10px 0;
      }
      .mobile {
        font-size: 14px;
        color: #999999;
        font-size: 13px;
      }
    }
  }
}
</style>